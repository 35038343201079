import { IApplicationAreaList, IMenuLink } from '@layout/models/layout-model';

export const HELP_TOUR_EVENT_NAME = 'helptourevent';

export const endomainArray = ['www.insights-eu-preprod.numantra.us',
  'eu.insights.bayer.com',
  'www.insights-eu-dev.numantra.us',
  'www.insights-eu-uat.numantra.us',
  'eu.test.insights.bayer.com',
  'www.insights-eu.numantra.us'];

export const footerRoutes = {
  tools_privacy: '/resources/privacy',
  tools_contactus: '/resources/contactus',
}

export const MAIN_MENU = {
  cdm_metrics: 'cdm-metrics',
  report: 'report',
  resources: 'resources',
  user: 'user',
};

export const OTHER_APPLICATION_AREAS: IApplicationAreaList[] = [
  {
    id: 'CT',
    label: 'translation.SelectionPanel.report_ct'
  },
  {
    id: 'CT-MP',
    label: 'translation.SelectionPanel.report_ctmp'
  },
  {
    id: 'MR',
    label: 'translation.SelectionPanel.report_mr'
  }
];

export const CONTRASTDASHBOARDMENU: IMenuLink[] = [
  {
    name: 'translation.ContrastMetricsSideNav.summary',
    imagePath: '../../../../assets/images/SummaryW.svg',
    activeImagePath: '../../../../assets/images/SummaryY.svg',
    url: '/cdm-metrics/my-dashboard',
  },
  {
    name: 'translation.ContrastDashboard.labelRepeatInjections',
    imagePath: '../../../../assets/images/RepeatInjectionW.svg',
    activeImagePath: '../../../../assets/images/RepeatInjectionY.svg',
    url: '/cdm-metrics/repeat-injections',
    tourAnchor: 'repeatinjections',
  },
  {
    name: 'translation.ContrastDashboard.labelIVIssues',
    imagePath: '../../../../assets/images/IVIssuesW.svg',
    activeImagePath: '../../../../assets/images/IVIssuesY.svg',
    url: 'cdm-metrics/iv-issues',
    tourAnchor: 'ivissues',
  },
  {
    name: 'translation.ContrastDashboard.labelLostTime',
    imagePath: '../../../../assets/images/LostTimeW.svg',
    activeImagePath: '../../../../assets/images/LostTimeY.svg',
    url: 'cdm-metrics/lost-time',
    tourAnchor: 'losttime',
  },
  {
    name: 'translation.ContrastDashboard.labelAtypicalTechAbort',
    imagePath: '../../../../assets/images/AtypicalTechnologistW.svg',
    activeImagePath: '../../../../assets/images/AtypicalTechnologistY.svg',
    url: 'cdm-metrics/tech-abort',
    tourAnchor: 'techabort',
  },
  {
    name: 'translation.ContrastDashboard.labelAtypicalFRL',
    imagePath: '../../../../assets/images/AtypicalFlowrateW.svg',
    activeImagePath: '../../../../assets/images/AtypicalFlowrateY.svg',
    url: 'cdm-metrics/flow-rate-limited',
    tourAnchor: 'flowratelimit',
  },
  {
    name: 'translation.ContrastDashboard.labelSalineTestInject',
    imagePath: '../../../../assets/images/SalineTestInjectW.svg',
    activeImagePath: '../../../../assets/images/SalineTestInjectY.svg',
    url: 'cdm-metrics/saline-test-inject',
    tourAnchor: 'salinetestinject',
  },
  {
    name: 'translation.ContrastDashboard.labelContrastWaste',
    imagePath: '../../../../assets/images/ContrastwasteW.svg',
    activeImagePath: '../../../../assets/images/ContrastwasteY.svg',
    url: 'cdm-metrics/contrast-waste',
    tourAnchor: 'unusedcontrast',
  },
  {
    name: 'translation.ContrastDashboard.labelDataCompleteness',
    imagePath: '../../../../assets/images/DataCompletenessW.svg',
    activeImagePath: '../../../../assets/images/DataCompletenessY.svg',
    url: 'cdm-metrics/point-of-care-data-quality',
    tourAnchor: 'datacompleteness',
  },
];

export const REPORTMENU: IMenuLink[] = [
  {
    name: 'translation.ReportSideNav.standardReport',
    imagePath: '../../../../assets/images/SummaryW.svg',
    activeImagePath: '../../../../assets/images/SummaryY.svg',
    url: 'report/standardreport',
    tourAnchor: '',
    className: 'reports-menu',
  },
  {
    name: 'translation.ReportSideNav.customizedReport',
    imagePath: '../../../../assets/images/SummaryW.svg',
    activeImagePath: '../../../../assets/images/SummaryY.svg',
    url: 'report/customizedreport',
    tourAnchor: '',
    className: 'reports-menu',
  },
  // {
  //   name: 'translation.ReportSideNav.serviceStandardReport',
  //   imagePath: '../../../../assets/images/SummaryW.svg',
  //   activeImagePath: '../../../../assets/images/SummaryY.svg',
  //   url: 'report/servicereport/standardreport',
  // },
  // {
  //   name: 'translation.ReportSideNav.serviceCustomizedReport',
  //   imagePath: '../../../../assets/images/SummaryW.svg',
  //   activeImagePath: '../../../../assets/images/SummaryY.svg',
  //   url: 'report/servicereport/customizedreport',
  // },
];
export const RESOURCESMENU: IMenuLink[] = [
  {
    name: 'translation.ToolsAndSettingsSideNav.tabContrastPDF',
    imagePath: '../../../../assets/images/SummaryW.svg',
    activeImagePath: '../../../../assets/images/SummaryY.svg',
    url: 'resources/contrast-guide',
    tourAnchor: '',
  },
  {
    name: 'translation.ToolsAndSettingsSideNav.tabHelpTour',
    imagePath: '../../../../assets/images/SummaryW.svg',
    activeImagePath: '../../../../assets/images/SummaryY.svg',
    url: 'service/metrics/summary/virtualcareactivity',
    event: HELP_TOUR_EVENT_NAME,
    tourAnchor: '',
  },
];
export const USERMENU: IMenuLink[] = [
  {
    name: 'translation.ToolsAndSettingsSideNav.userAdminPDF',
    imagePath: '../../../../assets/images/add-user@2x.png',
    activeImagePath: '../../../../assets/images/add-user@2x.png',
    url: 'user/admin',
  },
];
export const CONTRASTTITLE: Map<string, string> = new Map([
  ['LostTimeDataType', 'Lost Time,/'],
  ['PocDataQualityDataType', 'Data Completeness,/'],
  ['ContrastWasteDataType', 'Unused Contrast,/'],
  ['RepeatInjectionDataType', 'Repeat Injections,/'],
  ['AtypicalTechAbortDataType', 'Technologist Abort,/'],
  ['IVInjectionDataType', 'Abort With Repeat,/'],
  ['AtypicalFlowRateLimitedDataType', 'Flow Rate Limiting,/'],
  ['SalineTestInject', 'Saline Test Inject,/'],
]);

export const SERVICE_APPLICATION_AREA = 'service';
export const OTHER_APPLICATION_AREA = 'other';
export const SELECTEDREPORT = 'CT';
export const SELECTEDREPORT_CTM = 'CT-MP';
export const BLACK_COLOR = '#1e272a';
export const YELLOW_COLOR = '#e3ca41';
export const YELLOW_COLOR_ALPHA = '#f0d41540';
export const WHITE_COLOR = '#ffffff'
export const LIGHT_GRAY_COLOR = '#B3B6B7';
export const DARK_GRAY_COLOR = '#90969f';
export const LIGHT_PURPLE_ALPHA = '#9f7ad126';
export const LIGHT_PURPLE_COLOR = '#9f7ad1';
export const DANGER_COLOR = '#ec2227';
export const SUCCESS_COLOR = '#6bc200';
export const WARNING_COLOR = '#ffa000';

export const START_COUNT = 0;
export const END_COUNT = 10;
export const HOSPITAL_DISPLAY_COUNT=18;
export const ROUTES_NAME = {
  cdmMetrics: '/cdm-metrics',
  metrics_repeatInjections: '/cdm-metrics/repeat-injections',
  metrics_ivissues: '/cdm-metrics/iv-issues',
  metrics_atypicalInjection: '/cdm-metrics/tech-abort',
  metrics_flowratelimited: '/cdm-metrics/flow-rate-limited',
  metrics_lostTime: '/cdm-metrics/lost-time',
  metrics_contrastWaste: '/cdm-metrics/contrast-waste',
  metrics_pointOfCareDataQuality: '/cdm-metrics/point-of-care-data-quality',
  metrics_salineTestInject: '/cdm-metrics/saline-test-inject',
  metrics_benchmark: '/cdm-metrics/benchmark_accessroute',
  protocol_permission: '/cdm-metrics/protocol-permission',
  contrast_standard_report_print_sort: 'print',
  contrast_standard_report_print_long: '/report/standardreport/print',
  customized_report: '/report/customizedreport'
}

export const REPORT_MODE = {
  standard: 'standard',
  custom: 'custom',
  email: 'email'
}

export const REPORT_BASE_URL = '/report/';

export const DATEPICKER_FORMATS = {
  parse: {
    dateInput: 'MMM YYYY',
  },
  display: {
    dateInput: 'MMM  YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMM YYYY',
  },
};
